import { Text, Textarea, TextareaProps } from '@mantine/core';
import { forwardRef, useContext, useState } from 'react';

import { LayoutContext, LayoutType } from '../contexts/LayoutContext';

type CottageTextareaProps = TextareaProps & { maxChars?: number };

/**
 * @returns Mantine Textarea with optional character limit and counter.
 */
export const CottageTextarea = forwardRef<
  HTMLTextAreaElement,
  CottageTextareaProps
>(
  (
    {
      maxChars,
      defaultValue,
      value: externalValue,
      onChange: externalOnChange,
      ...props
    },
    ref
  ) => {
    const { layout } = useContext(LayoutContext);

    // In the event that the parent component passed in a value field,
    // it is assumed that it wants complete control of the component.
    // In that situation, value should serve as a character counter.
    // If not, use the default value, if passed in.

    // It is considered by React to be an error to pass both a defaultValue
    // and a normal value, and if a value is passed, onChange is required.
    // https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
    const [value, setValue] = useState(
      externalValue ??
        (defaultValue !== undefined
          ? maxChars !== undefined
            ? defaultValue.toString().slice(0, maxChars - 1)
            : defaultValue.toString()
          : '')
    );

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      const slicedValue = newValue.slice(0, maxChars ?? Infinity);

      if (
        !maxChars ||
        externalValue ||
        slicedValue.length <= (maxChars ?? Infinity)
      ) {
        externalOnChange?.(event);
        setValue(slicedValue);
      }
    };

    return (
      <>
        <Textarea
          ref={ref}
          size={layout === LayoutType.MOBILE ? 'md' : undefined}
          value={externalValue ?? value}
          onChange={handleChange}
          onKeyDown={(e) => {
            // Allow "Enter" to create a new line in the textarea
            if (e.key === 'Enter' && !e.shiftKey) {
              e.stopPropagation();
            }
          }}
          descriptionProps={{ style: { lineHeight: 1.5 } }}
          {...props}
        />
        {maxChars && (
          <Text
            align="right"
            color={value.toString().length > maxChars ? 'red' : 'blue.6'}
          >
            {value.toString().length}/{maxChars}
          </Text>
        )}
      </>
    );
  }
);

CottageTextarea.displayName = 'CottageTextarea';
