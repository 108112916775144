import { AxiosError } from 'axios';

import {
  CreateDocumentRequest,
  DocumentUploadUrlInfo,
  DocumentViewUrlInfo,
  DownloadDocuments,
  DownloadUrlsRequest,
  PgDocument,
  PgDocumentResponse,
  UpdateDocumentRequest,
  UploadUrlsRequest,
} from '@/types/api/documents';
import { HttpError } from '@/types/utilityTypes';

import { api } from './axios';

export const downloadUrls = async (req: DownloadUrlsRequest) => {
  try {
    const response = await api.post<DownloadDocuments[]>(
      '/api/documents/gen-download-urls',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned download urls ${axiosError.response?.data.message}`
    );
  }
};

export const uploadUrls = async (req: UploadUrlsRequest) => {
  try {
    const response = await api.post<{ [key: string]: DocumentUploadUrlInfo }>(
      '/api/documents/gen-upload-urls',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned upload urls ${axiosError.response?.data.message}`
    );
  }
};

export const generateGcsUploadSignedUrl = async (req: UploadUrlsRequest) => {
  try {
    const response = await api.post<{ [key: string]: DocumentUploadUrlInfo }>(
      '/api/documents/put-signed-url/create',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate GCS pre-signed upload urls ${axiosError.response?.data.message}`
    );
  }
};

export const viewUrlDigitalOcean = async (
  documentUuid: string,
  documentVersion?: number,
  triggerDownload?: boolean
) => {
  try {
    const queryParams = new URLSearchParams({
      ...(documentVersion && { documentVersion: documentVersion.toString() }),
      triggerDownload: triggerDownload ? 'true' : 'false',
    });
    const queryString = queryParams ? `?${queryParams.toString()}` : '';
    const response = await api.get<DocumentViewUrlInfo>(
      `/api/documents/gen-view-url/${documentUuid}${queryString}`
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned urls ${axiosError.response?.data.message}`
    );
  }
};

export const getViewUrl = async (
  documentUuid: string,
  documentVersion?: number,
  triggerDownload?: boolean
) => {
  try {
    const req = {
      documentUuid,
      documentVersion,
      triggerDownload,
    };

    const response = await api.post<DocumentViewUrlInfo>(
      `/api/documents/get-signed-url/create`,
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned GCS urls ${axiosError.response?.data.message}`
    );
  }
};

export const editDocument = async (
  documentUuid: string,
  req: UpdateDocumentRequest,
  version?: number
) => {
  try {
    const response = await api.patch(
      `/api/documents/${documentUuid}/update?version=${version}`,
      req
    );

    return response;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to edit document ${axiosError.response?.data.message}`
    );
  }
};

export const documentVersions = async (documentUuid: string) => {
  try {
    const response = await api.get<PgDocumentResponse[]>(
      `/api/documents/${documentUuid}/versions`
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to get document versions ${axiosError.response?.data.message}`
    );
  }
};

export const documentDetails = async (
  documentUuid: string,
  version?: number
) => {
  try {
    const response = await api.get<PgDocumentResponse>(
      `/api/documents/${documentUuid}?version=${version}`
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to get document details ${axiosError.response?.data.message}`
    );
  }
};

export const createDocument = async (
  projectUuid: string,
  req: CreateDocumentRequest
) => {
  try {
    const response = await api.post<PgDocument>(
      `/api/projects/${projectUuid}/documents/create`,
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to generate presigned upload urls ${axiosError.response?.data.message}`
    );
  }
};
