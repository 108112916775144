import * as amplitude from '@amplitude/analytics-browser';
import { createStyles, Flex, Group } from '@mantine/core';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { useContext, useEffect, useState } from 'react';
import { TbMenu2, TbX } from 'react-icons/tb';

import { AnalyticsEvent } from '@/lib/constants/analyticsEvents';
import { Role } from '@/lib/constants/role';
import useCustomer from '@/lib/hooks/useCustomer';
import { getRole } from '@/lib/roleUtils';

import { XsellLogo } from '../onboarding/XsellLogo';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { useSupportModal } from '../shared/contexts/SupportModalContext';
import Menu from './Menu';
import { SupportButton } from './SupportButton';
import UserMenu, { UserMenuCompactDisplayStrategy } from './UserMenu';

export const NAVIGATION_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  container: {
    padding: '0 5%',
    color: 'white',
    // must explicitly set height here for links to fully span nav bar height
    height: NAVIGATION_HEIGHT,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      justifyContent: 'space-between',
    },
    '@media print': {
      display: 'none',
    },
  },
  cottageLogo: {
    cursor: 'pointer',
  },
  mobileMenuButton: {
    cursor: 'pointer',
  },
}));

export const Navigation = ({ session }: { session: Session | null }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const { openSupportModal } = useSupportModal();

  const role = getRole(session?.user?.roles ?? []);
  const { customer } = useCustomer(/*shouldCall */ role === Role.CUSTOMER);

  const router = useRouter();
  const { classes } = useStyles();

  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;
  const isTablet = layout === LayoutType.TABLET;

  useEffect(() => {
    if (!isMobile) {
      setIsMobileNavOpen(false);
    }
  }, [isMobile]);

  const handleClickSupportButton = () => {
    amplitude.track({
      event_type: AnalyticsEvent.SUPPORT_BUTTON_CLICKED,
    });
    openSupportModal();
  };

  return (
    <Flex
      className={classes.container}
      direction="row"
      align="center"
      bg="blue.9"
      gap={isTablet ? 'sm' : 'xl'}
    >
      <XsellLogo
        color="white"
        hasRenofiAccount={!!customer?.hasRenofiAccount}
      />
      {session && !router.asPath.includes('/auth/signin') ? (
        <>
          {(!isMobile || isMobileNavOpen) && (
            <Menu
              role={role}
              userEmail={session.user.email ?? ''}
              userName={session.user.name ?? ''}
              setIsMobileNavOpen={setIsMobileNavOpen}
              hasRenofiAccount={!!customer?.hasRenofiAccount}
            />
          )}

          {/* Right Navigation */}
          {isMobile ? (
            <Group>
              <SupportButton onClick={handleClickSupportButton} size="sm" />
              {isMobileNavOpen ? (
                <TbX
                  className={classes.mobileMenuButton}
                  onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                  size={24}
                />
              ) : (
                <TbMenu2
                  className={classes.mobileMenuButton}
                  onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                  size={24}
                />
              )}
            </Group>
          ) : (
            <Group ml="auto" spacing={isTablet ? 'lg' : 'xl'} noWrap>
              <SupportButton
                onClick={handleClickSupportButton}
                size={isTablet ? 'sm' : 'lg'}
              />
              {/* If updating props here, be sure to update the mobile 
                  version of it in the Menu component */}
              <UserMenu
                userName={session.user.name ?? ''}
                userEmail={session.user.email ?? ''}
                displayStrategy={UserMenuCompactDisplayStrategy.ICON_ONLY}
                hasRenofiAccount={!!customer?.hasRenofiAccount}
              />
            </Group>
          )}
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};
