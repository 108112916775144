import {
  Anchor,
  Group,
  MantineNumberSize,
  Stack,
  Text,
  Title,
  TitleOrder,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CompanyMedal } from '@prisma/client';

import { trimWebsiteForDisplay } from '@/lib/stringUtils';
import { Company } from '@/types/api/company';
import { ProposalCompany } from '@/types/api/proposals';

import { CompanyAttributes } from '../proposals/attributes/CompanyAttributes';
import ProfileAvatar from '../shared/ProfileAvatar';
import { CompanyProfileQuickView } from './CompanyProfileQuickView';
import { SuperProLaurel } from './SuperProLaurel';

type ProfileHeaderProps = {
  company: ProposalCompany | Company;
  avatarSize?: MantineNumberSize;
  displayAttribute?: boolean;
  displayWebsite?: boolean;
  enableQuickView?: boolean;
  hideMedal?: boolean;
  nameTitleOrder?: TitleOrder;
  projectMunicipality?: string;
  truncateNameLength?: number;
};

export const ProfileHeader = ({
  company,
  projectMunicipality,
  nameTitleOrder = 3,
  displayAttribute,
  displayWebsite,
  hideMedal,
  enableQuickView,
  avatarSize = 'lg',
  truncateNameLength,
}: ProfileHeaderProps) => {
  const [
    openedProfileQuickView,
    { open: openProfileQuickView, close: closeProfileQuickView },
  ] = useDisclosure(false);

  const companyName =
    truncateNameLength &&
    company?.name &&
    company.name.length > truncateNameLength
      ? `${company?.name.substring(0, truncateNameLength - 3)}...`
      : company?.name;

  const showSuperProLaurel =
    !hideMedal && company?.medal === CompanyMedal.SUPERPRO;

  return (
    <Group align="start" noWrap>
      <ProfileAvatar
        style={{
          cursor: enableQuickView ? 'pointer' : 'default',
        }}
        profilePictureName={company?.profile_picture}
        onClick={enableQuickView ? openProfileQuickView : undefined}
        size={avatarSize}
        topRightIcon={
          showSuperProLaurel ? <SuperProLaurel size={avatarSize} /> : undefined
        }
      />
      <Stack spacing="xxxs">
        {enableQuickView ? (
          <Anchor component="span" onClick={openProfileQuickView}>
            <Title order={nameTitleOrder} lineClamp={2}>
              {companyName}
            </Title>
          </Anchor>
        ) : (
          <Title order={nameTitleOrder}>{companyName}</Title>
        )}

        <Text variant="subtitle">
          {company?.city}, {company?.state}
        </Text>
        {displayWebsite && company?.website && (
          <Text underline variant="subtitle">
            <Anchor
              href={company.website}
              target="_blank"
              rel="noopener noreferrer"
              underline
            >
              {trimWebsiteForDisplay(company.website)}
            </Anchor>
          </Text>
        )}

        {displayAttribute && <CompanyAttributes company={company} limit={1} />}
      </Stack>
      <CompanyProfileQuickView
        company={company}
        onClose={closeProfileQuickView}
        opened={openedProfileQuickView}
        projectMunicipality={projectMunicipality}
      />
    </Group>
  );
};
