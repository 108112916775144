import { Button, Stack, Text } from '@mantine/core';
import { useState } from 'react';

const INITIAL_PRO_MUNICIPALITY_COUNT = 2;

type ProMunicipalitiesAttributeContentProps = {
  proMunicipalities: string[];
};

export const ProMunicipalitiesAttributeContent = ({
  proMunicipalities,
}: ProMunicipalitiesAttributeContentProps) => {
  const [allProMunicipalitiesExpanded, setAllProMunicipalitiesExpanded] =
    useState(false);

  const toggleMunicipalitiesExpanded = () => {
    setAllProMunicipalitiesExpanded((isExpanded) => !isExpanded);
  };

  const proMunicipalitiesToShow = allProMunicipalitiesExpanded
    ? proMunicipalities
    : proMunicipalities.slice(0, INITIAL_PRO_MUNICIPALITY_COUNT);

  const hiddenProMunicipalities =
    proMunicipalities.length - proMunicipalitiesToShow.length;

  const areMunicipalitiesExpandable =
    proMunicipalities.length > INITIAL_PRO_MUNICIPALITY_COUNT;

  return (
    <Stack spacing="xxxxs">
      <Text>Experienced Municipalities</Text>
      {proMunicipalitiesToShow.length === 0 ? (
        <Text variant="subtitle">No municipalities</Text>
      ) : (
        proMunicipalitiesToShow.map((municipality) => (
          <Text key={municipality} variant="subtitle">
            {municipality}
          </Text>
        ))
      )}
      {areMunicipalitiesExpandable && (
        <Button
          variant="subtle"
          onClick={toggleMunicipalitiesExpanded}
          size="xs"
          w="fit-content"
          p={0}
          styles={{ label: { textDecoration: 'underline' } }}
        >
          {hiddenProMunicipalities > 0
            ? `+${hiddenProMunicipalities} more`
            : 'See less'}
        </Button>
      )}
    </Stack>
  );
};
