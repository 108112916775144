import { Text } from '@mantine/core';
import { useRouter } from 'next/router';

import BaseModal from '../BaseModal';
import ActionButton from '../buttons/ActionButton';

export type StaleDataModalOptions = {
  resourceName?: string;
};

type StaleDataModalProps = {
  onClose: () => void;
  opened: boolean;
  options?: StaleDataModalOptions;
};

export const StaleDataModal = ({
  onClose,
  opened,
  options,
}: StaleDataModalProps) => {
  const router = useRouter();

  const resourceNameToUse = options?.resourceName || 'information';

  return (
    <BaseModal
      opened={opened}
      onClose={onClose}
      title="Oops! Something changed"
      primaryAction={
        <ActionButton onClick={router.reload}>Refresh Page</ActionButton>
      }
    >
      <Text fz="sm">
        It looks like the {resourceNameToUse} has been updated since you last
        refreshed the page. To proceed, please refresh the page to get the
        latest data.
      </Text>
    </BaseModal>
  );
};
