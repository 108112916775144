import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import { StaleDataModalOptions } from '../modals/StaleDataModal';

type StaleDataModalContextType = {
  closeStaleDataModal: () => void;
  openStaleDataModal: (options?: StaleDataModalOptions) => void;
  staleDataModalOpened: boolean;
  staleDataModalOptions?: StaleDataModalOptions;
};

const StaleDataModalContext = createContext<
  StaleDataModalContextType | undefined
>(undefined);

export const StaleDataModalProvider = ({ children }: PropsWithChildren) => {
  const [staleDataModalOpened, setStaleDataModalOpened] = useState(false);
  const [staleDataModalOptions, setStaleDataModalOptions] =
    useState<StaleDataModalOptions>();

  const openStaleDataModal = useCallback((options?: StaleDataModalOptions) => {
    setStaleDataModalOptions(options);
    setStaleDataModalOpened(true);
  }, []);

  const closeStaleDataModal = useCallback(() => {
    setStaleDataModalOpened(false);
    setStaleDataModalOptions(undefined);
  }, []);

  return (
    <StaleDataModalContext.Provider
      value={{
        openStaleDataModal,
        closeStaleDataModal,
        staleDataModalOpened,
        staleDataModalOptions,
      }}
    >
      {children}
    </StaleDataModalContext.Provider>
  );
};

export const useStaleDataModal = (): StaleDataModalContextType => {
  const context = useContext(StaleDataModalContext);
  if (context === undefined) {
    throw new Error(
      'useStaleDataModal must be used within a StaleDataModalProvider'
    );
  }

  return context;
};
