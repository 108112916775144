import { PUBLIC_ENV } from './constants/environment/clientEnvVars';

// TODO(DEVOPS-132): Remove vercel env var references

export enum Env {
  LOCAL, // Local development
  PREVIEW, // Pull request specific deploy
  PRODUCTION,
  STAGING,
}

const getEnvironment = () => {
  if (
    process.env.VERCEL_ENV === 'production' ||
    PUBLIC_ENV.CONTEXT === 'production'
  ) {
    return Env.PRODUCTION;
  } else if (
    process.env.VERCEL_ENV === 'preview' ||
    PUBLIC_ENV.CONTEXT === 'branch-deploy'
  ) {
    return Env.STAGING;
  } else if (PUBLIC_ENV.CONTEXT === 'deploy-preview') {
    return Env.PREVIEW;
  }

  return Env.LOCAL;
};

export const isProd = () => getEnvironment() === Env.PRODUCTION;
export const isLocalDev = () => getEnvironment() === Env.LOCAL;

/**
 * Returns the fully qualified path for the given relative path.
 * @param relativePath The relative path that should be appended to the base
 * url. Can omit the leading slash. E.g. "api/proposals/123"
 * @returns The full url of the path to the resource for the given environment.
 */
export const getFullBlueprintUrl = ({
  relativePath,
  queryParams,
}: {
  relativePath?: string;
  queryParams?: URLSearchParams;
} = {}) => {
  const basePath = getBaseUrl();
  let path = '';
  if (relativePath) {
    path = relativePath.startsWith('/') ? relativePath.slice(1) : relativePath;
  }

  const fullPath = path ? `${basePath}/${path}` : basePath;

  if (queryParams && queryParams.toString()) {
    return `${fullPath}?${queryParams.toString()}`;
  }

  return fullPath;
};

const getBaseUrl = () => {
  if (getEnvironment() === Env.LOCAL) {
    return 'http://localhost:3000';
  } else if (process.env.NEXT_PUBLIC_BASE_URL) {
    // Used by Vercel
    return process.env.NEXT_PUBLIC_BASE_URL;
  } else if (PUBLIC_ENV.URL) {
    // Used by Netlify
    if (
      [Env.PREVIEW, Env.STAGING].includes(getEnvironment()) &&
      PUBLIC_ENV.DEPLOY_PRIME_URL
    ) {
      return PUBLIC_ENV.DEPLOY_PRIME_URL;
    }

    return PUBLIC_ENV.URL;
  }

  throw new Error('Unknown build environment encountered');
};
