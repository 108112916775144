/**
 * IMPORTANT: Only put variables that can be exposed on the client in this file.
 * All other variables belong in the server only file, or scoped to 'functions'
 * only on Netlify. The keys defined here will be substituted with their actual
 * values at build time with `envsubst`.
 *
 * The format of these values need to match the spelling of the process.env
 * value.
 */

const CONTEXT = 'production';
const DEPLOY_PRIME_URL = 'https://main--renofi-cottage.netlify.app';
const NEXT_PUBLIC_BASE_URL = '';
const NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN = '7pMawQP9qJEZ9HLbRL54jGV9';
const NEXT_PUBLIC_RENOVATION_CALCULATOR =
  'https://calculator.renofi.com/public?embed=true';
const NEXT_PUBLIC_SENTRY_DSN = 'https://49aa939b1be14ab0959109dc94ffd162@o159989.ingest.us.sentry.io/4505160761802752';
const NEXT_PUBLIC_SHOW_MAINTENANCE = '0';
const NEXT_PUBLIC_STRIPE_PUBLIC_KEY = 'pk_live_51H0M63B10yTNLySoEuBiWmxM7UPyVCj1613dfzNpHLI7LOZgeYi56kOwovgZaSBEitYKNC6zym4FFunZ2DVgHeUa00C4LJMgai';
const URL = 'https://app.cotta.ge';

const ENV_VARS = {
  CONTEXT,
  DEPLOY_PRIME_URL,
  NEXT_PUBLIC_BASE_URL,
  NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN,
  NEXT_PUBLIC_RENOVATION_CALCULATOR,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SHOW_MAINTENANCE,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  URL,
} as const;

export const PUBLIC_ENV = {
  CONTEXT: process.env.CONTEXT || ENV_VARS.CONTEXT,
  DEPLOY_PRIME_URL: process.env.DEPLOY_PRIME_URL || ENV_VARS.DEPLOY_PRIME_URL,
  NEXT_PUBLIC_BASE_URL:
    process.env.NEXT_PUBLIC_BASE_URL || ENV_VARS.NEXT_PUBLIC_BASE_URL,
  NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN:
    process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN ||
    ENV_VARS.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN,
  NEXT_PUBLIC_RENOVATION_CALCULATOR:
    process.env.NEXT_PUBLIC_RENOVATION_CALCULATOR ||
    ENV_VARS.NEXT_PUBLIC_RENOVATION_CALCULATOR,
  NEXT_PUBLIC_SENTRY_DSN:
    process.env.NEXT_PUBLIC_SENTRY_DSN || ENV_VARS.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SHOW_MAINTENANCE:
    process.env.NEXT_PUBLIC_SHOW_MAINTENANCE ||
    ENV_VARS.NEXT_PUBLIC_SHOW_MAINTENANCE,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY:
    process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ||
    ENV_VARS.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  URL: process.env.URL || ENV_VARS.URL,
} as const;
