import { track as amplitudeTrack } from '@amplitude/analytics-browser';
import {
  createStyles,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSession } from 'next-auth/react';
import { TbChevronRight } from 'react-icons/tb';

import { AnalyticsEvent } from '@/lib/constants/analyticsEvents';
import useCompany from '@/lib/hooks/useCompany';
import { validateRoles } from '@/lib/roleUtils';
import { Project } from '@/types/api/projects';

import { AvailabilityDot } from '../company/AvailabilityDot';
import { ChangeAvailabilityModal } from '../company/ChangeAvailabilityModal';
import { CompanyProfileQuickView } from '../company/CompanyProfileQuickView';
import ProfileAvatar from '../shared/ProfileAvatar';
import { UserMenuDropdown } from './UserMenuDropdown';

export enum UserMenuCompactDisplayStrategy {
  FULL_WIDTH,
  ICON_ONLY,
}

const useMobileStyles = createStyles((theme) => ({
  userInfo: {
    justifySelf: 'flex-start',
    marginTop: 'auto',
    padding: theme.spacing.md,
    width: '100%',
    borderTop: `1px solid ${theme.colors.gray[1]}`,
    '&:hover': {
      background: theme.colors.blue[0],
    },
  },
}));

type UserMenuProps = {
  displayStrategy: UserMenuCompactDisplayStrategy;
  hasRenofiAccount: boolean;
  userEmail: string;
  userName: string;
  project?: Project;
};

export default function UserMenu({
  displayStrategy,
  hasRenofiAccount,
  userEmail,
  userName,
}: UserMenuProps) {
  const { data: session } = useSession({ required: true });
  const { isArchitect, isGc } = validateRoles(session?.user?.roles ?? []);
  const { company, loading: isLoadingCompany } = useCompany();

  const { classes: mobileClasses } = useMobileStyles();
  const [
    openedProfileQuickView,
    { open: openProfileQuickView, close: closeProfileQuickView },
  ] = useDisclosure(false);
  const [
    isChangeAvailabilityModalOpen,
    { open: openChangeAvailabilityModal, close: closeChangeAvailabilityModal },
  ] = useDisclosure(false);

  const containerClasses =
    displayStrategy === UserMenuCompactDisplayStrategy.FULL_WIDTH
      ? mobileClasses.userInfo
      : '';

  const showOpenToWork = isArchitect && !!company?.availability;

  return (
    <Stack className={containerClasses}>
      <Menu
        position="bottom-end"
        shadow="0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)"
        styles={(theme) => ({
          itemIcon: {
            color: theme.colors.actionActive,
          },
          itemLabel: {
            color: theme.colors.textPrimary,
          },
        })}
      >
        <Menu.Target>
          <UnstyledButton
            onClick={() => {
              amplitudeTrack({
                event_type: AnalyticsEvent.PROFILE_ICON_CLICKED,
              });
            }}
          >
            {displayStrategy === UserMenuCompactDisplayStrategy.FULL_WIDTH ? (
              <Flex gap="xs" align="center">
                <Group style={{ flexGrow: 1 }}>
                  <ProfileAvatar userName={userName} />
                  <Stack spacing="xxxs">
                    <Text fw="600">{userName}</Text>
                    <Text c="gray.6">{userEmail}</Text>
                  </Stack>
                </Group>
                <TbChevronRight />
              </Flex>
            ) : (
              <ProfileAvatar
                userName={userName}
                topRightIcon={
                  showOpenToWork ? (
                    <AvailabilityDot availability={company.availability} />
                  ) : undefined
                }
              />
            )}
          </UnstyledButton>
        </Menu.Target>
        <UserMenuDropdown
          displayStrategy={displayStrategy}
          onClickChangeAvailability={openChangeAvailabilityModal}
          onClickViewProfile={openProfileQuickView}
          showOpenToWork={showOpenToWork}
          showProfileQuickView={isArchitect || isGc}
          showRenofiDashboardLink={hasRenofiAccount}
          userName={userName}
          userEmail={userEmail}
          company={company}
        />
      </Menu>
      {company && (
        <CompanyProfileQuickView
          company={company}
          onClose={closeProfileQuickView}
          opened={openedProfileQuickView}
          showAllProMunicipalities
        />
      )}
      <ChangeAvailabilityModal
        onClose={closeChangeAvailabilityModal}
        opened={isChangeAvailabilityModalOpen}
        availability={company?.availability}
        isLoading={isLoadingCompany}
      />
    </Stack>
  );
}
