import { Group, Text } from '@mantine/core';

import { CottageTooltip } from '@/components/shared/default';
import { isString } from '@tiptap/react';

export type AttributeProps = {
  icon: JSX.Element;
  content: JSX.Element | string;
  tooltipText?: string;
};

export const Attribute = ({ icon, content, tooltipText }: AttributeProps) => {
  return (
    <Group noWrap spacing="xxs" align="top">
      {icon}
      <CottageTooltip label={tooltipText} w={200}>
        {isString(content) ? (
          <Text c="blue.9" style={{ cursor: 'default' }}>
            {content}
          </Text>
        ) : (
          content
        )}
      </CottageTooltip>
    </Group>
  );
};
