import {
  Anchor,
  Box,
  DefaultMantineColor,
  Divider,
  Group,
} from '@mantine/core';

import CottageLogoCompact from '../shared/svg/CottageLogoCompact';
import RenofiLogo from '../shared/svg/RenofiLogo';

type XsellLogoProps = {
  color: DefaultMantineColor;
  containerStyle?: React.CSSProperties;
  hasRenofiAccount?: boolean;
};

export const XsellLogo = ({
  color,
  containerStyle,
  hasRenofiAccount = false,
}: XsellLogoProps) => {
  return (
    <Group style={containerStyle}>
      <Anchor w={24} href="/">
        <CottageLogoCompact color={color} />
      </Anchor>
      <Divider orientation="vertical" size="xs" color={color} />
      <Box
        w={100}
        component={hasRenofiAccount ? 'a' : 'div'}
        href={hasRenofiAccount ? 'https://dashboard.renofi.com' : undefined}
        target="_blank"
      >
        <RenofiLogo color={color} />
      </Box>
    </Group>
  );
};
