import { Badge, BadgeProps, Group, Text } from '@mantine/core';
import { HighlightTag } from '@prisma/client';
import { useContext } from 'react';
import {
  TbAward,
  TbBolt,
  TbCalendar,
  TbClockCheck,
  TbClockHour4,
  TbCurrentLocation,
  TbGenderFemale,
  TbHeartHandshake,
  TbHome,
  TbHome2,
  TbLeaf,
  TbLicense,
  TbMessage,
  TbPigMoney,
  TbReportMoney,
  TbRotateDot,
  TbRuler,
  TbSocial,
  TbSofa,
  TbStar,
  TbSun,
  TbTools,
  TbVector,
  TbVideo,
  TbWorld,
} from 'react-icons/tb';

import {
  DisplaySize,
  SizeContext,
} from '@/components/shared/contexts/SizeContext';
import {
  CottageTooltip,
  CottageTooltipProps,
} from '@/components/shared/default';

export enum HighlightBadgeCompactDisplayStrategy {
  FULL_WIDTH,
  ICON_ONLY,
}

type TagInfo = {
  text: string;
  badgeColor?: string;
  color?: string;
  icon?: JSX.Element;
  toolTipInfo?: string;
};

type HighlightBadgeProps = {
  highlightTag: HighlightTag;
  displayStrategy?: HighlightBadgeCompactDisplayStrategy;
  hideToolTip?: boolean;
  toolTipProps?: Partial<Omit<CottageTooltipProps, 'children'>>;
} & BadgeProps;

export const highlightTags: Partial<Record<HighlightTag, TagInfo>> = {
  // GC tags
  [HighlightTag.BUILD_QUALITY]: {
    icon: <TbTools />,
    text: 'Build Quality',
    toolTipInfo: 'Delivers top-tier craftsmanship.',
  },
  [HighlightTag.CONSTRUCTION_SPEED]: {
    icon: <TbCalendar />,
    text: 'Construction Speed',
    toolTipInfo: 'Top 25% of builders for construction speed.',
  },
  [HighlightTag.CONSTRUCTION_TIMELINE]: {
    icon: <TbCalendar />,
    text: 'Construction Timeline',
  },
  [HighlightTag.QUALITY_CRAFTSMANSHIP]: {
    icon: <TbRuler />,
    text: 'Quality Craftsmanship',
  },
  [HighlightTag.TIMELY_EXECUTION]: {
    icon: <TbClockHour4 />,
    text: 'Timely Execution',
  },
  [HighlightTag.POWER_BUILDER]: {
    icon: <TbBolt />,
    text: 'Power Builder',
    toolTipInfo:
      'Top builder in your market based on experience with Cottage and ' +
      'homeowner feedback.',
  },

  // Architect tags
  [HighlightTag.ADU_SPECIALIST]: {
    icon: <TbHome2 />,
    text: 'ADU Specialist',
  },
  [HighlightTag.AWARD_WINNING]: {
    icon: <TbAward />,
    text: 'Award-Winning',
  },
  [HighlightTag.BUDGET_CONSCIOUS]: {
    icon: <TbReportMoney />,
    text: 'Budget Conscious',
    toolTipInfo:
      'Provides cost-effective solutions. Maximizes value within budget ' +
      'constraints.',
  },
  [HighlightTag.CUSTOM_MILLWORK]: {
    icon: <TbTools />,
    text: 'Custom Millwork',
  },
  [HighlightTag.DESIGN_BUILD]: {
    icon: <TbVector />,
    text: 'Design-Build',
    toolTipInfo:
      'Offers in-house services for both design and construction. Enables ' +
      'close coordination to meet design intent and budget.',
  },
  [HighlightTag.DESIGN_COLLECTIVE]: {
    icon: <TbSocial />,
    text: 'Design Collective',
  },
  [HighlightTag.FULL_SERVICE]: {
    icon: <TbRotateDot size={24} />,
    text: 'Full Service',
  },
  [HighlightTag.GREEN_BUILDING]: {
    icon: <TbLeaf />,
    text: 'Green Building',
  },
  [HighlightTag.HIGHLY_EXPERIENCED]: {
    icon: <TbStar />,
    text: 'Highly Experienced',
    toolTipInfo: 'Has completed 10+ projects in your local area.',
  },
  [HighlightTag.IN_HOUSE_BUILDER]: {
    icon: <TbHome />,
    text: 'In-house Builder',
  },
  [HighlightTag.INTERIOR_DESIGNER]: {
    icon: <TbSofa />,
    text: 'Interior Designer',
  },
  [HighlightTag.INTERNATIONAL]: {
    icon: <TbWorld />,
    text: 'International',
  },
  [HighlightTag.LICENSED_ARCHITECT]: {
    icon: <TbLicense />,
    text: 'Licensed Architect',
    toolTipInfo:
      'Holds an AIA license in at least one state. Requires extensive ' +
      'education and testing',
  },
  [HighlightTag.LOCAL_PRESENCE]: {
    icon: <TbCurrentLocation />,
    text: 'Local Presence',
  },
  [HighlightTag.PASSIVE_HOUSE_DESIGNER]: {
    icon: <TbSun />,
    text: 'Passive House Designer',
  },
  [HighlightTag.QUALITY]: {
    icon: <TbTools />,
    text: 'Quality',
  },
  [HighlightTag.REMOTE_DESIGNER]: {
    icon: <TbVideo />,
    text: 'Remote Designer',
  },
  [HighlightTag.SEAMLESS_COORDINATION]: {
    icon: <TbHeartHandshake />,
    text: 'Seamless Coordination',
    toolTipInfo:
      'Ensures smooth collaboration with engineers and contractors for a ' +
      'hassle-free experience.',
  },
  [HighlightTag.TIMELINE]: {
    icon: <TbCalendar />,
    text: 'Timeline',
  },
  [HighlightTag.TIMELY_DELIVERABLES]: {
    icon: <TbClockCheck />,
    text: 'Timely Deliverables',
    toolTipInfo:
      'Meets deadlines consistently. Ensures projects stay on schedule.',
  },
  [HighlightTag.WOMEN_OWNED]: {
    icon: <TbGenderFemale />,
    text: 'Women Owned',
  },

  // Shared tags
  [HighlightTag.COMMUNICATION]: {
    icon: <TbMessage />,
    text: 'Good Communicator',
    toolTipInfo: 'Responds quickly & clearly. Provides proactive updates.',
  },
  [HighlightTag.COST]: {
    icon: <TbPigMoney />,
    text: 'Budget Expectations',
    toolTipInfo: 'Competitive pricing for top-tier service.',
  },
  [HighlightTag.EXPERIENCE]: {
    text: 'Highly Experienced', // TODO: confirm UI copy, icon, and tooltip
  },
  [HighlightTag.REFERENCES]: {
    text: 'Good References', // TODO: confirm UI copy, icon, and tooltip
  },
  [HighlightTag.OTHER]: {
    text: 'Other',
  },
};

const defaultIcon = <TbTools />;
const defaultColor = 'blue.9';

// Hide OTHER highlight tag because it's not useful to be displayed in the UI
const tagsToHide = new Set<HighlightTag>([HighlightTag.OTHER]);

export const HighlightBadge = ({
  highlightTag,
  displayStrategy = HighlightBadgeCompactDisplayStrategy.FULL_WIDTH,
  hideToolTip,
  toolTipProps,
  ...otherProps
}: HighlightBadgeProps) => {
  const { size } = useContext(SizeContext);
  const isCompact = size === DisplaySize.COMPACT;

  const tagInfo = highlightTags[highlightTag];

  if (!tagInfo || tagsToHide.has(highlightTag)) {
    return <></>;
  }

  const {
    badgeColor = 'white',
    color = defaultColor,
    icon = defaultIcon,
    text,
    toolTipInfo,
  } = tagInfo;

  const badgeContent =
    isCompact &&
    displayStrategy === HighlightBadgeCompactDisplayStrategy.ICON_ONLY ? (
      icon
    ) : (
      <Group align="center" spacing="xxxs">
        {icon}
        <Text c={color} fz="12px" fw={500} style={{ textTransform: 'none' }}>
          {text}
        </Text>
      </Group>
    );

  const badge = (
    <Badge
      c={color}
      variant="light"
      radius="lg"
      size="xl"
      color={badgeColor}
      py="md" // Should remain 16px if theme spacing changes
      px="xs" // Should remain 12px if theme spacing changes
      sx={(theme) => ({
        border: `1px solid ${theme.colors.gray[4]}`,
        cursor: 'default',
      })}
      {...otherProps}
    >
      {badgeContent}
    </Badge>
  );

  if (hideToolTip) {
    return badge;
  }

  return (
    <CottageTooltip
      label={toolTipInfo}
      w={200}
      disabled={!toolTipInfo}
      {...toolTipProps}
    >
      {badge}
    </CottageTooltip>
  );
};
